import axios from './axios-client';

export const getStates = async (setData = f => f, setLoading = f => f, setError = f => f) => {
  try {
    setLoading(true)
    const data = await axios.get(`state`);
    setData(data.data);
    setLoading(false);
    return data.data;
  } catch (error) {
    setError(error)
    setLoading(false)
  }
};
