import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, FieldCheckbox, FieldCheckboxGroup, FieldRadioButton, FieldBirthdayInput } from '../../../components';

import css from './SignupForm.module.css';
import { getStates } from '../../../services/states';

const KEY_CODE_ENTER = 13;
const identity = v => v;

const SignupFormComponent = props => {
  const [states, setStates] = useState([]);

  useEffect(() => {
    getStates(setStates)
  }, []);

  return (
    <FinalForm
        initialValues={{
          iam: "Wine Lover"
        }}
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const required = validators.required('A valid date is required');
        const minAge = 21;
        const minAgeRequired = validators.ageAtLeast(`We're sorry, but due to legal restrictions, we are unable to create an account for you on Sommly.com. You must be of valid drinking age in your state of residence to purchase wine, age should be at least ${minAge}`, minAge);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        const options = [
          {
            key: 'towels',
            label: 'Towels',
          },
        ]

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <div className={css.inputWrapper}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
              <div className={css.inputWrapper}>
                <FieldBirthdayInput
                  id={`birth`}
                  name="birth"
                  label="Date of birth"
                  format={identity}
                  // valueFromForm={values.birthday}
                  validate={validators.composeValidators(required, minAgeRequired)}
                />
                <FieldSelect
                  rootClassName={css.rootInput}
                  id="state"
                  name="state"
                  label="State"
                  validate={validators.required('State is required')}
                >
                  <option value="">Pick your state</option>
                  {states && states.map(st => (
                    <option key={st.value} value={st.name}>{st.name}</option>
                  ))}
                </FieldSelect>
              </div>
              <div className={css.inputWrapper}>
                <FieldTextInput
                  type="text"
                  id='company'
                  name="company"
                  autoComplete="company"
                  label='Company Name (optional)'
                  // placeholder={emailPlaceholder}
                  // validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldTextInput
                  className={css.password}
                  type="text"
                  id='zip'
                  name="zip"
                  autoComplete="zip"
                  label='Zip'
                  // placeholder={passwordPlaceholder}
                  validate={validators.required('Zip code is required')}
                />
              </div>
              <div className={css.inputWrapper}>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'singp-email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
              </div>
              <div >
                <p style={{textAlign: 'center', marginBottom: "0"}}>I’m a...</p>
                <div className={css.iamWrapper}>
                  <FieldRadioButton
                    className={css.radioBtn}
                    id='Sommelier'
                    name="iam"
                    label="Sommelier"
                    value="Sommelier"
                  />
                  <FieldRadioButton
                    className={css.radioBtn}
                    id='WineLover'
                    name="iam"
                    label="Wine Lover"
                    value="Wine Lover"
                  />
                  <FieldRadioButton
                    className={css.radioBtn}
                    id='IndustryProfessional'
                    name="iam"
                    label="Industry Professional"
                    value="Industry Professional"
                  />
                  <FieldRadioButton
                    className={css.radioBtn}
                    id='WineNewbie'
                    name="iam"
                    label="Wine Newbie"
                    value="Wine Newbie"
                  />
                </div>
              </div>
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}>
                <span className={css.termsText}>
                  <FormattedMessage
                    id="SignupForm.termsAndConditionsAcceptText"
                    values={{ termsLink }}
                  />
                </span>
              </p>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  )
}

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
